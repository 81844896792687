.wallet {
    display: flex;
    color: #fff;
    justify-content: flex-end;
    align-items: center;
    margin-right: 30px;
}
.address {
    margin-right: 30px;
}
