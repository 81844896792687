.home {
    background-color: #575bad;
    height: 100vh;
}

.landing {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
