* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

#root {
    height: 100%;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected {
    background-color: #575757 !important;
}

.ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover {
    background-color: #575757 !important;
}

.centerX {
    display: flex;
    justify-content: center;
}
